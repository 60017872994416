/* Generated by grunt-webfont */



@font-face {
	font-family:"icons";
	src:url("font/icons.eot?35b4a5abe6c7d01e36ab810e097a698d");
	src:url("font/icons.eot?35b4a5abe6c7d01e36ab810e097a698d#iefix") format("embedded-opentype"),
		url("font/icons.woff?35b4a5abe6c7d01e36ab810e097a698d") format("woff"),
		url("font/icons.ttf?35b4a5abe6c7d01e36ab810e097a698d") format("truetype");
	font-weight:normal;
	font-style:normal;
}

.ci {
	
		font-family:"icons";
	
	display:inline-block;
	line-height:1;
	font-weight:normal;
	font-style:normal;
	speak:none;
	text-decoration:inherit;
	text-transform:none;
	text-rendering:auto;
	-webkit-font-smoothing:antialiased;
	-moz-osx-font-smoothing:grayscale;
}


/* Icons */


.ci-cross-flip-l:before {
	content:"\f101";
}


.ci-cross-flip-r:before {
	content:"\f102";
}


.ci-cross:before {
	content:"\f103";
}


.ci-ds81:before {
	content:"\f104";
}


.ci-roseheart-flip:before {
	content:"\f105";
}


.ci-roseheart:before {
	content:"\f106";
}
