@font-face {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 300;
	src: url('font/montserrat-300-normal.woff') format('woff');
}
@font-face {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 400;
	src: url('font/montserrat-400-normal.woff') format('woff');
}
@font-face {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 600;
	src: url('font/montserrat-600-normal.woff') format('woff');
}
@font-face {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 700;
	src: url('font/montserrat-700-normal.woff') format('woff');
}
@font-face {
	font-family: 'Merriweather';
	font-style: normal;
	font-weight: 400;
	src: url('font/merriweather-400-normal.woff') format('woff');
}
@font-face {
	font-family: 'Bitter';
	font-style: normal;
	font-weight: 400;
	src: url('font/bitter-400-normal.woff') format('woff');
}
