
.accordion{
  .card{
    border-radius: 0;
    border: none;

    &+.card{
      margin-top: 1em;
    }

    .btn{
      background: none;
      padding: 0.4em 0.25em;
      border-radius: 0;
    }

    border-bottom-width: 0;
    &:last-child{
      border-bottom-width: 1px;
    }

    .card-header{
      padding: 0;
      border-bottom: none;
      .caption{
        cursor: pointer;
        background: $primary;
        color: $white;
        font-weight: 500;
        text-transform: none;
        transition: $transition-base;

        position: relative;

        .list-number{
          display: block;
          width: 40px;
          position: absolute;
          left: 16px;
          top: 16px;
        }
        .list-caption{
          display: block;
          padding-left: 40px;
          width: 100%;
        }


        &:hover{
          background: darken($primary, 1%);
          color: $white;
        }
        &[aria-expanded="true"]{

          transition: $transition-base;


          background: $primary;
          color: $white;

          &:hover{
            background: $primary;
            color: $white;
          }
        }
        &:focus{
          box-shadow: none;
        }
      }
    }
  }
}
