@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/mixins";
@import 'variables';
@import "../node_modules/bxslider/dist/jquery.bxslider.min.css";
@import "../node_modules/@fancyapps/fancybox/dist/jquery.fancybox.css";


$hamburger-padding-x           : 12px;
$hamburger-padding-y           : 15px;
$hamburger-layer-width         : 15px;
$hamburger-layer-height        : 2px;
$hamburger-layer-spacing       : 4px;
$hamburger-layer-color         : $white;
$hamburger-layer-border-radius : 4px;
$hamburger-hover-opacity       : 0.7;
$hamburger-active-layer-color  : $white;
$hamburger-active-hover-opacity: $hamburger-hover-opacity;


@import "../node_modules/hamburgers/_sass/hamburgers/hamburgers";
@import "../node_modules/awesome-bootstrap-checkbox/awesome-bootstrap-checkbox";
@import "font/fonts";
@import "font/fonts_legacy";
@import "font/icons";
@import "scss/_accordion";

$boxPadding: 4rem 6rem;
$boxPaddingSm: 2rem;

.font-light{
  font-weight: $font-weight-light;
}

.font-normal{
  font-weight: $font-weight-normal;
}

.font-bold{
  font-weight: $font-weight-bold;
}

body,html{
  min-height: 100%;
}

body{
  position: relative;
  color: $black;
  max-width: 100%;
  overflow-x: hidden;
}

.btn{
  &.btn-primary{
    color: $white;
  }
}

.bgimage{
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  max-height: 100vh;
  max-width: 100vw;
  overflow-x: hidden;
  background-position: center top;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

.bg-not-attached{
  background-attachment: scroll !important;
}

.container-fluid{
  max-width: 1600px;
}

header{
  z-index: 10;
  .brand-logo{
    display: block;
    width: 9vw;
    max-width: 260px;
    min-width: 200px;
    @include media-breakpoint-down(md){
      min-width: 200px;
      max-width: 200px;
    }
    @include media-breakpoint-down(sm){
      min-width: 140px;
      max-width: 140px;
    }
  }
  box-shadow: 1px 1px 6px rgb(0 0 0 / 30%);
}

ul{
  list-style-type: square;
}

.tf{
  color: $body-color;
  h1{
    margin-bottom: .9em;
  }
  h1 + p{
    margin-top: .8rem;
  }
  p{
    color: $body-color;
  }
  &.text-dimmed{
    p{
      color: #707f87;
    }
  }
  *:first-child{
    margin-top: 0 !important;
  }
  *:last-child{
    margin-bottom: 0 !important;
  }
  h1:last-child{
    margin-bottom: .9em !important;
  }

}

.focuspoint {
  position: relative; /*Any position but static should work*/
  overflow: hidden;
    img {
      position: absolute !important;
      left: 0;
      top: 0;
      margin: 0;
      display: block;
      /* fill and maintain aspect ratio */
      width: auto !important; height: auto;
      min-width: 100%; min-height: 100%;
      max-height: none; max-width: none;
    }
}

h1, h2, h3, h4, h5, h6{
  font-family: $font-family-alt;
  letter-spacing: .04em;
  color: $body-color;
}

.tf{
  h3{
    border-bottom: 1px solid rgba(0,0,0,0.4);
    text-align: center;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
  }
}
.ct{
  h1, h2, h3, h4, h5, h6{
    opacity: .8;
  }
  padding: $boxPadding;
  @include media-breakpoint-down(md){
    padding: $boxPaddingSm;
  }
}
.col-img{
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  @include media-breakpoint-down(md){
    height: 40vh;
  }
}

main{
  background: #fff;
  position: relative;
  z-index: 0;
  min-height: 100vh;
}

section.overlay-text{
  text-shadow: 1px 1px 6px rgb(0 0 0 / 30%);
  height: 100vh;
  overflow: hidden;
  text-align: center;
  background-color: rgba(0,0,0,0.1);
  h1{
    font-family: $font-family-sans-serif;
    font-weight: 700;
  }
  hr{
    height: 4px;
    opacity: 1;
    box-shadow: 1px 1px 6px rgb(0 0 0 / 30%);
  }
  .container{
    position: relative;
    z-index: 0;
    height: 100vh;
  }
  .mover{
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    *{
      color: $white !important;
    }
  }
}

section.intro{
  text-align: center;
  padding: $boxPadding;
  padding-top: 6rem;
  padding-bottom: 6rem;
  @include media-breakpoint-down(md){
    padding: $boxPaddingSm;
  }
}

.icon-separator{
  .sep{
    height: 2px;
    background: rgba(0,0,0,0.4);
    width: 100%;
    border-radius: 50%;
    overflow: hidden;
  }
}

.emergency-phone{
  a{
    font-size: 120%;
    font-weight: 400;
  }
  @include media-breakpoint-down(md){
    font-size: .9rem;
    text-align: center;
  }
  @include media-breakpoint-down(sm){
    font-size: .6rem;
    line-height: 1.3;
    text-align: center;
    a{
      display: block;
      margin-top: .2rem;
    }
  }
}

blockquote{
  font-size: 133%;
  font-family: "Bitter";
  font-style: italic;
  font-weight: 400;
  @include media-breakpoint-down(sm){
    font-size: 113%;
  }
}

footer{

  $footer-text-color: #c0c0c0;

  color: $footer-text-color;
  background-color: #404040;
  line-height: 2;
  letter-spacing: .03em;
  @include media-breakpoint-down(sm){
    font-size: 80%;
  }
  p{
    color: $footer-text-color;
  }
  b{
    font-size: 120%;
    display: block;
    margin-bottom: .3em;
  }
  a{
    color: $footer-text-color;
    &:hover{
      color: rgba(255,255,255,0.8);
    }
  }
  hr{
    background: #fff;
    height: 2px;
    opacity: 1;
  }
  .sep{
    display: inline-block;
    margin: 0 1em;
    @include media-breakpoint-down(md){
      display: block;
      visibility: hidden;
      height: 3px;
      overflow: hidden;
    }
  }
}

.fancybox-content.container-fluid{
  padding: 2rem;
  width: 1000px;
  max-width: 1000px;
  @include media-breakpoint-down(lg){
    width: 900px;
    max-width: 900px;
  }
  @include media-breakpoint-down(md){
    width: 80vw;
    max-width: 80vw;
  }
  @include media-breakpoint-down(sm){
    padding: $grid-gutter-width;
    width: 95vw;
    min-width: 95vw;
    max-width: 95vw;
  }
}

.nojs-fallback{
  background-color: #fff;
  padding: 5rem;
  border-bottom: 1px solid #000;
  &:nth-of-type(2)
  {
    background-color: $gray-300;
  }
  &:last-of-type{
    border: none;
  }
}

.map-embed-content{
  iframe{
    border: 1px solid $body-color !important;
    width: 100%;
    height: 40vh;
    @include media-breakpoint-down(sm){
      height: 80vh;
      width: 100% !important;
    }
  }
}
